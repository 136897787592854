@import "swiper/swiper-bundle.css";
/*@import 'swiper/css/bundle';*/
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: dinb;
  src: url("assets/fonts/din-fonts/ArbFONTS-DINNextLTArabic-Bold-2.ttf");
}
@font-face {
  font-family: sf;
  src: url("assets/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: sft;
  src: url("assets/fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: dinh;
  src: url("assets/fonts/din-fonts/ArbFONTS-DINNextLTArabic-Heavy-1.ttf");
}
@font-face {
  font-family: dinl;
  src: url("assets/fonts/din-fonts/ArbFONTS-DINNEXTLTARABIC-LIGHT-1.ttf");
}
@font-face {
  font-family: dinb;
  src: url("assets/fonts/din-fonts/ArbFONTS-DINNextLTArabic-Medium-2.ttf");
}
@font-face {
  font-family: dinr;
  src: url("assets/fonts/din-fonts/ArbFONTS-DINNextLTArabic-Regular-2.ttf");
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none !important;
}
* {
  outline: none !important;
  line-height: auto !important;
  box-sizing: border-box;
  touch-action: manipulation;
}
/*----------------------- body-home ---------------------- */
body {
  font-family: dinr;
  background: #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.english {
  direction: ltr !important;
}
.arabic {
  direction: rtl !important;
}
.arabic .col-md-1,
.arabic .col-md-2,
.arabic .col-md-3,
.arabic .col-md-4,
.arabic .col-md-5,
.arabic .col-md-6,
.arabic .col-md-7,
.arabic .col-md-8,
.arabic .col-md-9,
.arabic .col-md-10,
.arabic .col-md-11,
.arabic .col-md-12 {
  float: right;
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 15px;
}
.col-md-3-custom {
  width: 25%;
}
.english .col-md-1,
.english .col-md-2,
.english .col-md-3,
.english .col-md-4,
.english .col-md-5,
.english .col-md-6,
.english .col-md-7,
.english .col-md-8,
.english .col-md-9,
.english .col-md-10,
.english .col-md-11,
.english .col-md-12 {
  float: left;
  padding-right: 10px;
}
.arabic .col-md-offset-3 {
  margin-right: 25%;
  margin-left: 0;
}




.New_login_form {
  width: 100%;
  background-color: rgba(240, 255, 248, 1);
  padding: 40px 20px;
}

.New_login_form span {
  float: right;
  width: 100%;
  text-align: center;
  color: rgba(130, 130, 130, 1);
  font-size: 14px;
}

.welcome_title_text {
  margin-bottom: 20px;
}

.welcome_title_text h3,
.welcome_title_text p {
  color: rgba(89, 89, 89, 1);
}



.signInEn {
  color: rgba(5, 105, 56, 1);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.signInAr {
  color: rgba(5, 105, 56, 1);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.signInEn::after {
  content: "";
  position: absolute;
  bottom: -15px;
  width: 200px;
  height: 5px;
  background: linear-gradient(90deg, #056938, transparent);
  left: 0;
}




.signInAr::after {
  content: "";
  position: absolute;
  bottom: -15px;
  width: 200px;
  height: 5px;
  background: linear-gradient(200deg, #056938, transparent);
  right: 0;
}

.passwordEye {
  background-color: #fff0 !important;
  color: #aaaaaa !important;
  cursor: pointer;
}


.loginBtn {
  display: flex;
  border: none;
  background: #fff;
  padding: 5px 10px;
  box-shadow: 0px 4px 8px 0px rgba(194, 194, 194, 0.25);
  border-radius: 12px;
  align-items: center;
}

.google {
  color: rgba(32, 111, 242, 1);
  margin: 0 5px;
  font-size: 18px;
  font-weight: bold;
}


.facebook_google_icon {
  width: 28px;
  height: 28px;
}


.errorColor {
  color: #a94442 !important;
}

.loginOption{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around !important;
}


.signInBtn {
  float: right;
  width: 100%;
  border: 0;
  font-size: 14px;
  padding: 8px 15px 10px;
  color: #fff;
  background: #056938;
  margin-top: 15px;
  border-radius: 30px;
}

.input_15 .email {
  background-color: transparent !important;
  color: #056938 !important;
  font-size: 18px !important;
}

.new_input_15 .email {
  background-color: transparent !important;
  color: #056938 !important;
  font-size: 18px !important;
}


.small {
  color: #056938;
  font-weight: bold;
  font-size: 16px;
}

.loginPageDogImg2 {
  width: 200px;
  height: 200px;
  margin-top: 30px;
}

.english .col-md-offset-3 {
  margin-left: 25%;
  margin-right: 0;
}
.container-fluid,
.navbar-collapse {
  padding: 0;
}
.mainu {
  float: right;
  margin-left: 20px;
}

.list_helps {
  float: right;
  width: 100%;
  margin-bottom: 45px;
}
.owl-prev {
  position: absolute;
  left: -7px;
  width: 40px;
  height: 40px;
  background: #dded;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  border-radius: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.owl-next.disabled,
.owl-prev.disabled {
  display: none !important;
}
.list_marks_icons .owl-prev {
  top: 30%;
}
.arabic .owl-next {
  position: absolute;
  right: -35px;
  width: 40px;
  height: 40px;
  background: #dded;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  border-radius: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.english .owl-next {
  position: absolute;
  right: -47px;
  width: 40px;
  height: 40px;
  background: #dded;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  border-radius: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.list_help b {
  float: right;
  width: 100%;
  color: #a3a3a3;
  font-size: 14px;
}
.list_help .img_lihelp {
  float: right;
  width: 100%;
  position: relative;
  height: 40px;
  margin-bottom: 10px;
}
.list_help img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 30px;
  max-width: 35px;
}
.list_help p {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
.list_help {
  float: right;
  width: 100%;
  background: #f2f2f2;
  border-bottom: 3px solid #056938;
  padding: 15px;
  padding-top: 25px;
  text-align: center;
}
section {
  float: right;
  width: 100%;
}
.head_sec-ar h3:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 4px;
  height: 18px;
  width: 12px;
  background: #056938;
  border-radius: 2px;
}
.head_sec-en h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 24px;
  width: 12px;
  background: #056938;
  border-radius: 2px;
}
.head_sec-ar h3 {
  float: right;
  width: 100%;
  padding-right: 25px;
  position: relative;
  font-size: 22px;
  /*font-weight: bold;*/
  color: black;
}
.btn-map {
  background: #056938;
  color: #fff !important;
  padding: 4px 20px 12px;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 8px;
  text-decoration: none;
  display: inline-block;
  width: auto;
}
.footer {
  overflow-x: hidden;
}
.footer .logo {
  padding: 0 !important;
}
.btn-map:hover {
  text-decoration: none;
  color: white !important;
}
.head_sec-en h3 {
  float: left;
  width: 100%;
  padding-left: 25px;
  position: relative;
  font-size: 22px;
  /*font-weight: bold;*/
  color: black;
}
.head_sec-ar {
  width: 100%;
  margin-bottom: 25px;
  padding: 15px 0;
  position: relative;
}
.head_sec-en {
  width: 100%;
  margin-bottom: 25px;
  padding: 15px 0;
  position: relative;
}
.list_marks_icons2 .item a img,
.list_marks_icons .item a img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  display: inline-block;
  transform: translate(-50%, -50%);
  min-width: 50px;
  max-height: 100%;
}
.in_amobile {
  display: none;
  margin-top: 15px;
}
.list_marks_icons2 .item a,
.list_marks_icons .item a {
  float: right;
  width: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 15px;
  height: 98px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}
.list_marks_icons2 .item,
.list_marks_icons .item {
  float: right;
  width: 100%;
}
.list_marks_icons2,
.list_marks_icons {
  float: right;
  width: 100%;
  padding: 30px 0;
}
.by_types {
  /* margin: 40px 0; */
  padding: 20px 0;
  background: #f9f9f9;
}
.arabic .slid_page {
  float: right;
  width: 100%;
}
.english .slid_page {
  float: left;
  width: 100%;
}
.image_slid_page .ngxImageZoomContainer,
.image_slid_page .ngxImageZoomThumbnail {
  width: 100% !important;
  height: 100% !important;
}
.image_slid_page {
  float: right;
  width: 100%;
  border-radius: 18px;
  background: #fff;
  /*overflow: hidden;*/
  position: relative;
  height: 340px;
  margin-bottom: 15px;
}
.arabic .item_list_slid_page {
  float: right;
  width: 100px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 90px;
  margin-bottom: 15px;
}
.english .item_list_slid_page {
  float: right;
  width: 100px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 90px;
  margin-bottom: 15px;
}
.item_list_slid_page img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  display: inline-block;
  width: auto !important;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}
.slid {
  float: right;
  width: 100%;
  padding: 35px 0;
}
.list_slid_page {
  float: right;
  width: 100%;
}
.arabic .slid_page .owl-prev {
  left: 21;
  right: auto;
  top: 33px;
}
.english .slid_page .owl-prev {
  left: -45px;
  right: auto;
  top: 33px;
}

.arabic .slid_page .owl-next {
  top: 33px;
}
.english .slid_page .owl-next {
  top: 33px;
  right: 0;
}
.con_con-ar {
  float: right;
  width: 100%;
}
.con_con-en {
  float: left;
  width: 100%;
}
.con_con-ar h3 {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.con_con-en h3 {
  float: left;
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.con_con-ar p {
  float: right;
  width: 100%;
  color: #575757;
  font-size: 14px;
}
.con_con-en p {
  float: left;
  width: 100%;
  color: #575757;
  font-size: 14px;
}
.bor {
  float: right;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 15px;
}
.con_con-ar ul li.disc:before {
  content: "";
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  height: 1px;
  background: #a7a7a7;
  width: 100%;
}
.con_con-en ul li.disc:before {
  content: "";
  position: absolute;
  top: 58%;
  transform: translateY(50%);
  height: 1px;
  background: #a7a7a7;
  width: 100%;
}
.con_con-ar ul li.disc {
  float: right;
  margin-right: 15px;
  color: #a7a7a7;
  position: relative;
  font-size: 18px;
  font-weight: normal;
  margin-top: 10px;
}
.con_con-en ul li.disc {
  float: left;
  margin-left: 15px;
  color: #a7a7a7;
  position: relative;
  font-size: 18px;
  font-weight: normal;
  margin-top: 10px;
}
.con_con-ar ul li {
  float: right;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
}
.con_con-en ul li {
  float: left;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
}
.con_con-ar ul {
  float: right;
  width: 100%;
  margin-bottom: 25px;
}
.con_con-en ul {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}
.con_des {
  padding: 25px 0;
}
.content {
  float: right;
  width: 100%;
  padding: 10px 0;
}
.content span {
  float: right;
  padding-left: 25px;
}
.arabic .inputs.content_input button:first-child {
  left: auto;
  right: 0;
}
.english .inputs.content_input button:first-child {
  right: auto;
  left: 0;
}
.arabic .inputs.content_input button {
  position: absolute;
  left: 0;
  top: 0;
  color: #747474;
  border: 0;
  font-size: 10px;
  padding: 5px 10px;
  height: 100%;
}
.sb-button button {
  width: auto !important;
  height: auto !important;
  background: none !important;
  margin-left: auto !important;
}
.english .inputs.content_input button {
  position: absolute;
  right: 0;
  top: 0;
  color: #747474;
  border: 0;
  font-size: 10px;
  padding: 5px 10px;
  height: 100%;
}
.arabic .inputs.content_input input {
  float: right;
  width: 150px;
  background: #f2f2f2;
  padding: 5px 12px 5px 12px;
  font-size: 12px;
  color: #333;
  border: 0;
  font-weight: bold;
  text-align: center;
}
.english .inputs.content_input input {
  float: left;
  width: 150px;
  background: #f2f2f2;
  padding: 5px 12px 5px 12px;
  font-size: 12px;
  color: #333;
  border: 0;
  font-weight: bold;
  text-align: center;
}
.arabic .inputs.content_input {
  float: right;
  position: relative;
  border-radius: 6px;
  width: auto;
  overflow: hidden;
}
.english .inputs.content_input {
  float: left;
  position: relative;
  border-radius: 6px;
  width: auto;
  overflow: hidden;
}
.plus,
.minus {
  background-color: #c0c0c0;
}
.add_like {
  border: 1px solid #d6e2db;
  background-color: #e4ede7;
  color: #30663d;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 2;
}
.likesw2 {
  border: 1px solid #d6e2db !important;
  background-color: #f2f2f2 !important;
  color: #30663d !important;
  height: 35px !important;
  /* width: 35px; */
  text-align: center !important;
  line-height: 2 !important;
  width: 90px !important;
}
.arabic .inputs {
  float: right;
}
.english .inputs {
  float: left;
}
.con_con-ar .btn_item_products button:first-child {
  background: #056938;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  height: 35px;
  line-height: 2;
}
.con_con-en .btn_item_products button:first-child {
  background: #056938;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  height: 35px;
  line-height: 2;
}
.con_con-ar .btn_item_products .dropdown-share button:last-child {
  background: #f2f2f2;
}
.con_con-en .btn_item_products button:last-child {
  background: #f2f2f2;
}
.con_con-ar .btn_item_products button {
  float: right;
  margin-left: 15px;
}
.con_con-en .btn_item_products button {
  float: left;
  margin-right: 15px;
}
.slid .list_help .img_lihelp {
  margin-bottom: 0;
}
.slid .list_help img {
  display: inline-block;
  width: 20px;
  margin-bottom: 5px;
}
.slid .list_help p {
  font-size: 10px;
}
.slid .list_help b {
  font-size: 10px;
}
.slid .list_help {
  padding: 5px 10px;
  margin-bottom: 15px;
}
.head_list_con_page h3 {
  float: right;
  width: 100%;
  background: #056938;
  color: #fff;
  padding: 5px 10px 10px;
}
.listss_con_page,
.lists_con_page,
.head_list_con_page,
.list_con_page {
  float: right;
  width: 100%;
}
.item_lists_con_page > div {
  display: table-cell;
  vertical-align: middle;
}
.item_lists_con_page {
  float: right;
  width: 100%;
  display: table;
  padding: 15px 10px;
  background: #fff;
  border-bottom: 1px solid #eee;
}
.img_item_lists_con_page a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.img_item_lists_con_page a {
  float: right;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 70px;
}
.img_item_lists_con_page {
  width: 75px;
  padding-left: 10px;
}
.text_item_lists_con_page ul li:last-child button {
  float: right;
  width: 100%;
  color: #2d673d;
  font-size: 10px;
  font-weight: normal;
  background: #f2f2f2;
  padding: 2px 10px 5px;
  border-radius: 15px;
  border: 1px solid #2d673d;
  margin-top: 6px;
}
.text_item_lists_con_page ul li:last-child {
  float: left;
}
.text_item_lists_con_page ul li b {
  float: right;
  width: 100%;
}
.text_item_lists_con_page ul li:first-child {
  width: Calc(100% - 90px);
}
.text_item_lists_con_page ul li {
  float: right;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}
.text_item_lists_con_page ul {
  float: right;
  width: 100%;
}
.text_item_lists_con_page p {
  float: right;
  width: 100%;
  color: #000;
  font-size: 14px;
  margin-bottom: 8px;
}
.text_item_lists_con_page ul li span:before {
  content: "";
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  height: 1px;
  background: #a7a7a7;
  width: 100%;
}
.text_item_lists_con_page ul li span {
  float: right;
  color: #a7a7a7;
  position: relative;
  font-size: 12px;
  font-weight: normal;
}
.english .custom-md-8 {
  float: right !important;
  margin-left: 8.3% !important;
  margin-right: 0 !important;
}
.arabic .lists_con_page .owl-next {
  top: -30px;
  right: auto;
  left: 45px;
}
.english .lists_con_page .owl-next {
  top: -30px;
  left: auto;
  right: -5px;
}
.arabic .lists_con_page .owl-prev {
  top: -30px;
  left: 20px;
}
.english .lists_con_page .owl-prev {
  top: -30px;
  right: 20px;
}
.lists_con_page {
  position: relative;
}
.con_page_diss.active {
  max-height: initial;
}
.con_page_disss.active {
  max-height: initial;
}
.con_page_diss {
  float: right;
  width: 100%;
}
.con_page_disss {
  float: right;
  width: 100%;
}
.con_page_dis {
  float: right;
  width: 100%;
}
.arabic .con_page_dis .more {
  float: right;
  border-radius: 25px;
  color: #1b92dd;
  font-size: 12px;
  padding: 5px 10px 10px;
  background: #f2f2f2;
  margin-top: 15px;
  cursor: pointer;
}

.BaseCursor{
  cursor: pointer !important;
}
.english .con_page_dis .more {
  float: left;
  border-radius: 25px;
  color: #1b92dd;
  font-size: 12px;
  padding: 5px 10px 10px;
  background: #f2f2f2;
  margin-top: 15px;
  cursor: pointer;
}
.con_page_diss p {
  float: right;
  width: 100%;
  color: #575757;
  font-size: 16px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}
.con_page_disss p {
  float: right;
  width: 100%;
  color: #575757;
  font-size: 16px;
  margin-bottom: 4px;
}
.con_page_dis h3 {
  float: right;
  width: 100%;
  color: #056938;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.filter {
  padding: 30px 0;
}
.list_filter-ar {
  float: right;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
}
.list_filter-ar h3 i {
  display: inline-block;
  margin-left: 10px;
}
.list_filter-ar h3 {
  float: right;
  width: 100%;
  padding: 5px 10px 10px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  background: #ebebeb;
}
.list_filter-en {
  float: left;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
}
.list_filter-en h3 i {
  display: inline-block;
  margin-right: 10px;
}
.list_filter-ar h3 {
  float: left;
  width: 100%;
  padding: 5px 10px 10px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  background: #ebebeb;
}
.list_filter-en h3 {
  float: right;
  width: 100%;
  padding: 5px 10px 10px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  background: #ebebeb;
}
.lists_folter-ar > ul,
.lists_folter-en > ul {
  padding: 5px 0;
}
.lists_folter-ar ul li span img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 90%;
  max-height: 90%;
  transform: translate(-50%, -50%);
}
.lists_folter-en ul li span img {
  position: absolute;
  right: 50%;
  top: 50%;
  max-width: 90%;
  max-height: 90%;
  transform: translate(50%, -50%);
}
.lists_folter-ar ul li span {
  float: right;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
}
.lists_folter-en ul li span {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
}
.lists_folter-ar > ul > li:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.lists_folter-en > ul > li:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.lists_folter-ar > ul > li ul li a {
  float: right;
  width: 100%;
  padding: 5px 10px 10px;
  font-size: 14px;
  color: #000000;
  background: #eee;
}
.lists_folter-en > ul > li ul li a {
  float: left;
  width: 100%;
  padding: 5px 10px 10px;
  font-size: 14px;
  color: #000000;
  background: #eee;
}
.lists_folter-ar > ul > li ul li:last-child,
.lists_folter-en > ul > li ul li:last-child {
  border-bottom: 0;
}
.lists_folter-ar > ul > li ul li {
  float: right;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.lists_folter-en > ul > li ul li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.lists_folter-ar > ul > li.active ul,
.lists_folter-en > ul > li.active ul,
.lists_folter-ar > ul > li:hover ul,
.lists_folter-en > ul > li:hover ul {
  display: block;
}
.lists_folter-ar > ul > li ul {
  float: right;
  width: 100%;
  padding: 10px 0 5px;
  display: none;
}
.lists_folter-en > ul > li ul {
  float: left;
  width: 100%;
  padding: 10px 0 5px;
  display: none;
}
.lists_folter-ar > ul > li:last-child,
.lists_folter-en > ul > li:last-child {
  border-bottom: 0;
}
.lists_folter-ar > ul > li {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 16px;
  position: relative;
  padding: 5px 10px 10px;
  border-bottom: 1px solid #e2e2e2;
}
.lists_folter-en > ul > li {
  float: left;
  width: 100%;
  color: #000000;
  font-size: 16px;
  position: relative;
  padding: 5px 10px 10px;
  border-bottom: 1px solid #e2e2e2;
}
.lists_folter-ar ul,
.lists_folter-ar {
  float: right;
  width: 100%;
}
.lists_folter-en ul,
.lists_folter-en {
  float: left;
  width: 100%;
}

.beforeround:before,
.english .checkbox input:checked + .beforeround:before{
  background-color: #056938 !important;
  color: red !important;
}


.arabic .checkbox input:checked + label:before,
.english .checkbox input:checked + label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  background: #056938;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  color: #fff;
}
.arabic .checkbox label:before {
  content: "";
  position: absolute;
  right: 15px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #a39f9f;
}
.english .checkbox label:before {
  content: "";
  position: absolute;
  left: 15px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #a39f9f;
}
.arabic .checkbox label span {
  float: right;
  font-size: 12px;
  padding: 2px 2px 1px;
}
.english .checkbox label span {
  float: left;
  font-size: 12px;
  padding: 2px 2px 1px;
}
.arabic .checkbox label {
  float: right;
  width: 100%;
  padding: 2px 10px 8px;
  padding-right: 45px;
  color: #000000;
  font-size: 14px;
  position: relative;
}
.english .checkbox label {
  float: left;
  width: 100%;
  padding: 2px 10px 8px;
  padding-left: 45px;
  color: #000000;
  font-size: 14px;
  position: relative;
}
.arabic .checkbox input,
.english .checkbox input {
  display: none;
}
.arabic .checkbox:last-child,
.english .checkbox:last-child {
  border-bottom: 0;
}
.arabic .checkbox {
  float: right;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.english .checkbox {
  float: left;
  width: 100%;
  /* border-bottom: 1px solid #e2e2e2; */
}
.lists_folter-ar h3,
.lists_folter-en h3 {
  background: #e6ebeb;
}
.more_check {
  float: right;
  background: #fff;
  color: #1b92dd;
  font-size: 12px;
  padding: 2px 15px 6px;
  border-radius: 25px;
}
.pd2 {
  padding: 15px;
}
.filter_con {
  float: right;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  overflow-x: hidden;
}
.list_filter-ar,
.list_filter-en {
  margin-bottom: 15px;
}
.header_filter-ar h3 span {
  display: inline-block;
  background: #ebebeb;
  color: #000000;
  font-size: 12px;
  padding: 2px 10px 5px;
  border-radius: 15px;
  margin-right: 10px;
}
.header_filter-en h3 span {
  display: inline-block;
  background: #ebebeb;
  color: #000000;
  font-size: 12px;
  padding: 2px 10px 5px;
  border-radius: 15px;
  margin-left: 10px;
}
.header_filter-ar h3:before {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  height: 26px;
  background: #056938;
  width: 8px;
}
.header_filter-en h3:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  height: 26px;
  background: #056938;
  width: 8px;
}

.header_filter-ar h3 {
  float: right;
  font-size: 20px;
  padding: 5px 15px 7px;
  position: relative;
  color: #000000;
}
.header_filter-en h3 {
  float: left;
  font-size: 20px;
  padding: 5px 15px 7px;
  position: relative;
  color: #000000;
}

.header_filter-ar {
  float: right;
  width: 100%;
}
.header_filter-en {
  float: left;
  width: 100%;
}

.left_header_filter-ar {
  float: left;
}
.left_header_filter-en {
  float: right;
}
.arabic span.menu {
  float: right;
  margin-left: 15px;
  font-size: 18px;
  color: #fff;
  padding: 12px;
  cursor: pointer;
}
.arabic .logo {
  float: right;
  width: 200px;
  padding-top: 8px;
  max-width: 100%;
}
.arabic .logo a {
  float: right;
}
.arabic .head_mobile {
  float: right !important;
  width: 100% !important;
  background: #056938 !important;
  display: none;
}
.english .head_mobile {
  float: right !important;
  width: 100% !important;
  background: #056938 !important;
  display: none;
}
.arabic .list_sid2 > ul > li:before,
.arabic .list_sid > ul > li:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}

.english .list_sid2 > ul > li:before,
.english .list_sid > ul > li:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.english .list_sid2 > ul > .whatsapp-sidemenu:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.arabic .list_sid2 > ul > .whatsapp-sidemenu:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.arabic .head_mobile .list_carts {
  display: inline-block !important;
  float: left !important;
  width: auto !important;
  padding-top: 5px !important;
}
.english .head_mobile .list_carts {
  display: inline-block !important;
  float: right !important;
  width: auto !important;
  padding-top: 5px !important;
}
.arabic .list_sid > ul > li img {
  float: right;
  width: 20px;
  margin-left: 8px;
  padding-top: 2px;
}
.english .list_sid > ul > li img {
  float: left;
  width: 20px;
  margin-right: 8px;
  padding-top: 2px;
}
.arabic .head_mobile ::ng-deep .list_carts {
  display: inline-block !important;
  float: left !important;
  width: auto !important;
  padding-top: 5px !important;
}
.english .head_mobile ::ng-deep .list_carts {
  display: inline-block !important;
  float: right !important;
  width: auto !important;
  padding-top: 5px !important;
}
.arabic .logo a img {
  float: right;
  width: 100%;
}
.english .logo {
  float: left;
  width: 200px;
  padding-top: 8px;
  max-width: 100%;
}
.english .logo a {
  float: left;
}
.english .logo a img {
  float: left;
  width: 100%;
}

.english span.menu {
  float: left;
  margin-right: 15px;
  font-size: 18px;
  color: #fff;
  padding: 12px;
  cursor: pointer;
}
.arabic .sidmenu {
  position: absolute;
  right: 0;
  width: 84%;
  max-width: 320px;
  background: #f2f2f2;
  height: 100%;
  overflow-y: auto;
}
.english .sidmenu {
  position: absolute;
  left: 0;
  width: 84%;
  max-width: 320px;
  background: #f2f2f2;
  height: 100%;
  overflow-y: auto;
}
.arabic .list_carts ul li {
  float: left !important;
  margin-right: 15px !important;
}
.english .list_carts ul li {
  float: right;
  margin-left: 15px;
}
.left_header_filters ul li.active {
  color: #bebebe;
}
.left_header_filters ul li {
  float: right;
  padding: 5px;
  margin-left: 5px;
  color: #000000;
  font-size: 16px;
}
.left_header_filters ul {
  float: left;
}
.left_header_filters .sle {
  float: left;
  margin-right: 15px;
}
.left_header_filters {
  display: block;
}
.sle button {
  color: #000;
  background: #f2f2f2;
  padding: 5px 10px 8px;
  border-radius: 0;
  border: 0 !important;
}
.sle > div {
  width: auto !important;
}
.bs-caret:before {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  right: 6px;
  top: 12px;
  color: #000000;
  font-size: 10px;
}
.caret {
  display: none;
}
.left_header_filters2-ar {
  float: right;
  width: 100%;
  padding-top: 15px;
}
.left_header_filters2-en {
  float: left;
  width: 100%;
  padding-top: 15px;
}
.left_header_filters2-ar span {
  float: left;
  color: #000000;
  font-size: 12px;
  margin-right: 15px;
  padding-top: 5px;
}
.left_header_filters2-en span {
  float: right;
  color: #000000;
  font-size: 12px;
  margin-left: 15px;
  padding-top: 5px;
}
.left_header_filters2-ar ul li i {
  position: absolute;
  right: 4px;
  top: 6px;
  padding: 5px;
  cursor: pointer;
}
.left_header_filters2-en ul li i {
  position: absolute;
  left: 4px;
  top: 6px;
  padding: 5px;
  cursor: pointer;
}
.left_header_filters2-ar ul li {
  float: left;
  margin-right: 10px;
  background: #056938;
  padding: 5px 10px 8px;
  color: #fff;
  font-size: 12px;
  padding-right: 25px;
  position: relative;
}
.left_header_filters2-en ul li {
  float: right;
  margin-left: 10px;
  background: #056938;
  padding: 5px 10px 8px;
  color: #fff;
  font-size: 12px;
  padding-left: 25px;
  position: relative;
}
.left_header_filters2-ar ul {
  float: left;
}
.left_header_filters2-en ul {
  float: right;
}
.left_header_filters2-ar p .remove_market {
  float: left;
  color: #000000;
  font-size: 10px;
  padding: 5px 10px 8px;
  background: #ededed;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.left_header_filters2-en p .remove_market {
  float: right;
  color: #000000;
  font-size: 10px;
  padding: 5px 10px 8px;
  background: #ededed;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.left_header_filters2-ar p {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding-top: 15px;
}
.left_header_filters2-en p {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 12px;
  padding-top: 15px;
}
.slid_filter img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
.slid_filter {
  float: right;
  width: 100%;
  position: relative;
  height: 230px;
  overflow: hidden;
  margin: 15px 0;
}
.english .lists_filters .owl-next {
  right: -45px;
}
input::-webkit-inner-spin-button {
  display: none;
}
.lists_filters {
  float: right;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 15px;
}
.item_filter p {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 14px;
  text-align: center;
}
.item_filter a {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
}
.item_filter a,
.item_filter {
  float: right;
  width: 100%;
}

.img_item_filter {
  float: right;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 160px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list_alpha ul li a:hover,
.list_alpha ul li a.active {
  color: #fff;
  background: #056938;
}
.list_alpha ul li a {
  float: right;
  color: #056938;
  font-size: 14px;
  min-height: 36px;
  min-width: 30px;
  border-radius: 50%;
  padding: 5px 12px 10px;
}
.list_alpha ul li {
  display: inline-block;
  text-transform: capitalize;
}
.list_alpha ul {
  float: right;
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 7px 0 4px;
}
.list_alpha {
  float: right;
  width: 100%;
  margin: 15px 0 25px;
}
.ul-alpha-en {
  direction: ltr;
}
.ul-alpha-ar {
  direction: rtl;
}
.list_alpha2 {
  float: right;
  width: 100%;
  padding: 15px 0;
}
.list_alpha2 h4 {
  float: right;
  width: 100%;
  font-size: 24px;
  color: #056938;
  font-weight: bold;
  margin-bottom: 25px;
  text-transform: capitalize;
}
.list_alpha2 .text-en {
  text-align: left;
}
.list_alpha2 .text-ar {
  text-align: right;
}

.list_alpha3 ul li a:hover {
  padding-left: 15px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.list_alpha3 ul li .link-en {
  float: right;
  width: 100%;
  padding: 5px;
  color: #044b28;
  font-size: 14px;
  text-align: left;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.list_alpha3 ul li .link-ar {
  float: right;
  width: 100%;
  padding: 5px;
  color: #044b28;
  font-size: 14px;
  text-align: right;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.list_alpha3 ul li {
  float: right;
  width: 20%;
  margin-bottom: 2px;
}
.list_alpha3 ul {
  float: right;
  width: 100%;
}
.list_alpha3 {
  float: right;
  width: 100%;
  margin-bottom: 15px;
}
.text_p p {
  float: right;
  width: 100%;
  margin-bottom: 10px;
  color: #5a5a5a;
  font-size: 14px;
  text-align: justify;
}
.text_p h3 {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 7px;
}
.text_p {
  float: right;
  width: 100%;
  margin-bottom: 15px;
}
.text_p_img img {
  position: absolute;
  left: 50%;
  top: 50%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
.text_p_imgs:before {
  content: "";
  position: absolute;
  left: -50%;
  top: 26px;
  width: 100%;
  height: 100%;
  background: #056938;
  border-radius: 20px;
}
.text_p_imgs {
  float: right;
  width: 100%;
  height: 500px;
}
.text_p_img {
  float: right;
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}
.ov_hid {
  overflow: hidden;
}
.pd_20 {
  padding: 15px 0;
  margin: 0;
}
.contact-ar p {
  float: right;
  width: 100%;
  font-size: 10px;
  color: #404040;
  text-align: center;
  margin-bottom: 5px;
}
.contact-en p {
  float: left;
  width: 100%;
  font-size: 10px;
  color: #404040;
  text-align: center;
  margin-bottom: 5px;
}
.contact-ar h3 {
  float: right;
  width: 100%;
  font-size: 18px;
  color: #056938;
  text-align: center;
  margin-bottom: 15px;
}
.contact-en h3 {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #056938;
  text-align: center;
  margin-bottom: 15px;
}
.contact-ar {
  float: right;
  width: 100%;
  border-right: 8px solid #056938;
  background: #f2f2f2;
  border-top-left-radius: 39px;
  border-bottom-left-radius: 39px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 25px;
}
.contact-en {
  float: left;
  width: 100%;
  border-left: 8px solid #056938;
  background: #f2f2f2;
  border-top-right-radius: 39px;
  border-bottom-right-radius: 39px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 25px;
}
.input_s textarea,
.input_s input {
  float: right;
  width: 100%;
  border-radius: 18px;
  background: #fff;
  border: 0;
  color: #afafaf;
  font-size: 12px;
  padding: 5px 15px 10px;
  box-shadow: 0px 0px 14px rgb(0 0 0 / 9%);
}
.input_s button {
  display: block;
  margin: 0 auto;
  background: #056938;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px 10px;
  border: 0;
  text-align: center;
  min-width: 120px;
  border-radius: 20px;
}
.input_s {
  float: right;
  width: 100%;
  margin-bottom: 10px;
}
.contact_text-ar p i {
  display: inline-block;
  margin-left: 5px;
  color: #000;
}
.contact_text-en p i {
  display: inline-block;
  margin-right: 5px;
  color: #000;
}
.contact_text-ar a {
  float: right;
  width: 100%;
  color: #9d9d9c;
  font-size: 15px;
  margin-bottom: 10px;
}
.contact_text-en a {
  float: left;
  width: 100%;
  color: #9d9d9c;
  font-size: 15px;
  margin-bottom: 10px;
}
.contact_text-ar {
  float: right;
  width: 100%;
  padding: 30px 0;
}
.contact_text-en {
  float: left;
  width: 100%;
  padding: 30px 0;
}
.contact_text-ar ul li a {
  float: right;
  width: 30px;
  height: 30px;
  background: #ebebeb;
  color: #056938;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}
.contact_text-en ul li a {
  float: left;
  width: 30px;
  height: 30px;
  background: #ebebeb;
  color: #056938;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}
.contact_text-ar ul li:first-child:before {
  content: "";
  position: absolute;
  width: 2px;
  background: #9d9d9c;
  left: 0;
  top: 4px;
  height: 100%;
}
.contact_text-en ul li:first-child:before {
  content: "";
  position: absolute;
  width: 2px;
  background: #9d9d9c;
  right: 0;
  top: 4px;
  height: 100%;
}
.contact_text-ar ul li:first-child {
  padding-left: 15px;
  margin-left: 15px;
  position: relative;
}
.contact_text-en ul li:first-child {
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}
.contact_text-ar ul li {
  float: right;
  margin-left: 10px;
  font-size: 16px;
  color: #9d9d9c;
}
.contact_text-en ul li {
  float: left;
  margin-right: 10px;
  font-size: 16px;
  color: #9d9d9c;
}
.contact_text-ar ul {
  float: right;
  width: 100%;
  margin-top: 35px;
}
.contact_text-en ul {
  float: left;
  width: 100%;
  margin-top: 35px;
}
.img_contact img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.img_contact {
  float: right;
  width: 100%;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.english .img_contacts:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  content: "";
  position: absolute;
  height: 360px;
  width: 490px;
  left: -30px;
  bottom: -180px;
  background: url("assets/images/Path 204190.png") no-repeat center right;
  background-size: contain;
}
.arabic .img_contacts:before {
  content: "";
  position: absolute;
  height: 360px;
  width: 490px;
  right: -30px;
  bottom: -180px;
  background: url("assets/images/Path 204190.png") no-repeat center right;
  background-size: contain;
}
.img_contacts {
  float: right;
  width: 100%;
  position: relative;
}
.empty a {
  display: inline-block;
  width: 250px;
  border-radius: 15px;
  background: #2d673d;
  border-radius: 26px;
  max-width: 100%;
  color: #fff;
  text-align: center;
  padding: 5px 10px 10px;
}
.empty p i {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #056938;
  background: rgb(5 105 56 / 12%);
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  margin-right: 15px;
}
.empty p {
  display: inline-block;
  width: 100%;
  color: #a3a3a3;
  font-size: 16px;
  margin: 10px 0 25px;
}
.empty h3 {
  float: right;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}
.empty img {
  display: inline-block;
  margin-bottom: 15px;
  max-width: 100%;
}
.empty {
  float: right;
  width: 100%;
  padding: 30px 0;
  text-align: center;
}
.mod.empty {
  float: none;
  display: inline-block;
}
.mod.empty ul li:last-child a {
  border: 1px solid #2d673d;
  color: #2d673d;
  background: transparent;
}
.modal-content {
  border-radius: 25px;
}
.mod.empty ul li a {
  width: 180px;
  border: 1px solid #2d673d;
}
.mod.empty ul li {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 15px;
}
.mod.empty ul {
  float: right;
  width: 100%;
}
.list_6 {
  float: right;
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
}
.arabic .list_6 h3:before {
  position: absolute;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  left: 15px;
  font-size: 16px;
  top: 15px;
}
.english .list_6 h3:before {
  position: absolute;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  right: 15px;
  font-size: 16px;
  top: 15px;
}
.arabic .list_6.active h3:before {
  position: absolute;
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  left: 15px;
  font-size: 16px;
  top: 15px;
}
.english .list_6.active h3:before {
  position: absolute;
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  right: 15px;
  font-size: 16px;
  top: 15px;
}
.list_6.active .text_p {
  display: block;
}
.list_6 .text_p p {
  margin-bottom: 0;
}
.list_6 .text_p {
  display: none;
  padding: 5px 15px 10px;
  margin-bottom: 0;
}
.list_6 h3 {
  float: right;
  width: 100%;
  color: #26243e;
  font-size: 18px;
  padding: 5px 15px 10px;
  position: relative;
  cursor: pointer;
}
.list_img img {
  /* position: absolute; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* max-width: 90%; */
}
.list_img {
  float: right;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 400px;
}
.ts_7s:before {
  /* content: ""; */
  /* position: absolute; */
  /* left: -50%; */
  bottom: -20px;
  width: 100%;
  height: 90px;
  background: #056938;
}
.ts_7s {
  float: right;
  width: 100%;
  position: relative;
}
.ts_7 {
  border-radius: 15px;
  overflow: hidden;
  height: 300px;
}
.ts_7 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pd_20s {
  padding: 20px 0 70px;
  margin: 0;
}
.lists_7 {
  background: #056938;
  padding: 20px 0;
}
.img_list_7 img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1005;
}
.img_list_7 {
  float: right;
  width: 100%;
  height: 280px;
  position: relative;
  overflow: hidden;
}
.text_list_7 ul li:before {
  content: "";
  position: absolute;
  right: 0;
  top: 15px;
  background: url("assets/images/Group 41308.png") no-repeat center center;
  background-size: cover;
  width: 10px;
  height: 10px;
}
.text_list_7 ul li {
  float: right;
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 5px 0;
  padding-right: 25px;
  position: relative;
  margin-bottom: 10px;
}
.text_list_7 ul {
  float: right;
  width: 100%;
}
.text_list_7 {
  float: right;
  width: 100%;
  padding: 30px 0;
}
.ts_7s.w130 .ts_7 img {
  width: initial;
  max-width: initial;
}
.ts_7s.w130:before {
  width: 180%;
  right: -12%;
  height: 120px;
  bottom: -40px;
}
.data_15 {
  float: right;
  width: 100%;
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  margin-bottom: 25px;
}
.hde_15 h3 {
  float: right;
  width: 100%;
  padding-top: 25px;
  background: #f0f0f0;
  color: #000000;
  font-size: 16px;
  padding: 5px 10px 10px;
  text-align: center;
}
.hde_15 {
  float: right;
  width: 100%;
  padding: 35px 0 5px;
}
.inputs_all {
  float: right;
  width: 100%;
  padding: 20px 40px 30px;
}
.edit_pen {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.arabic .edit_pen a {
  float: left;
  width: 125px;
  height: 26px;
  font-size: 14px;
  line-height: 24px;
  color: #056938 !important;
  text-align: center;
}
.english .edit_pen a {
  float: right;
  width: 125px;
  height: 26px;
  font-size: 14px;
  line-height: 24px;
  color: #056938 !important;
  text-align: center;
}

.input_15 select {
  padding: 3px 5px 10px 10px !important;
  height: 35px;
}
.input_15 select,
.input_15 input {
  float: right;
  width: 100%;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #e3e3e3;
  font-size: 12px;
  padding: 5px 10px 5px 10px !important;
}
.input_15 {
  float: right;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.arabic .input_label label {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: right;
}
.english .input_label label {
  float: left;
  width: 100%;
  color: #000000;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}
.input_label {
  float: right;
  width: 100%;
  margin-bottom: 10px;
}
.input_15 i {
  position: absolute;
  width: 32px;
  /* right: 0; */
  /* height: 100%; */
  color: #fff;
  background: #056938;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}

.new_input_15 i {
  position: absolute;
  width: 32px;
  /* right: 0; */
  /* height: 100%; */
  color: #fff;
  background: #056938;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}



/* .menu_15 .logout {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 100%;
  padding: 10px 15px;
  color: #000000;
  font-size: 12px;
  border-right: 5px solid transparent;
} */

.f-16{
  font-size: 16px !important;
}



.brand_page .owl-carousel .owl-dots {
  text-align: center;
  width: 100% !important;
}

.brand_page .owl-carousel .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  background-color: #6d6b6b47;
  cursor: pointer; 
}

.brand_page .owl-carousel .owl-dot.active {
  width: 20px !important;
  background-color: #00000073;
  border-radius: 5px 5px !important;
}

.brand_page .owl-carousel .owl-dot span {
  display: none;
}


.f-14{
  font-size: 14px !important;
}

.arabic .menu_15 a i,
.arabic .menu_15 ul li a i {
  display: inline-block;
  margin-left: 7px;
  color: #056938;
  font-size: 14px;
}
.english .menu_15 a i,
.english .menu_15 ul li a i {
  display: inline-block;
  margin-right: 7px;
  color: #056938;
  font-size: 14px;
}
.arabic .menu_15 a:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.english .menu_15 a:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #000000;
  font-size: 10px;
}
.menu_15 ul li a {
  float: right;
  width: 100%;
  padding: 10px 15px;
  color: #000000;
  position: relative;
  font-size: 12px;
  border-right: 5px solid transparent;
}

.m-200{
  margin-top: 200px;
}




.menu_15 ul li {
  float: right;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
}
.menu_15 ul {
  float: right;
  width: 100%;
}
.menu_15 {
  float: right;
  width: 200px;
  max-width: 100%;
  background: #efefef;
  padding: 24px 0;
  border-radius: 10px;
  position: relative;
  min-height: 55vh;
  margin-bottom: 25px;
}
.menu_15 a.active,
.menu_15 a:hover {
  border-right: 5px solid #056938;
  background: rgb(5 105 56 / 08%);
  color: #044b28;
}

.npd {
  padding: 0;
}
.closw span {
  display: block;
  background: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 1;
  opacity: 1;
  border-radius: 50%;
  font-size: 14px;
  line-height: 28px;
  margin: 10px 0;
}
.header_mo h3 {
  float: right;
  width: 100%;
  background: #056938;
  color: #fff;
  padding: 15px 15px;
  text-align: center;
  font-size: 16px;
}
.header_mo {
  float: right;
  width: 100%;
  margin-bottom: 15px;
}
.modal-content.closw {
  overflow: hidden;
}
.modal-content.closw {
  overflow: hidden;
  box-shadow: none !important;
  background: transparent;
  border: 0;
}
.mod.empty.npd .input_label button {
  float: right;
  width: 100%;
  background: #056938;
  color: #fff;
  font-size: 14px;
  border-radius: 7px;
  padding: 8px 15px 10px;
  border: 0;
}
.mod.empty.npd {
  float: right;
  width: 100%;
  background: #fff;
  border-radius: 35px;
  overflow: hidden;
  text-align: right;
}
.mark2top a {
  height: 125px;
}
.mark2top a:first-child {
  margin-bottom: 15px;
}
.arabic .chsks .checkbox,
.english .chsks .checkbox {
  border-bottom: 0;
}
.arabic .chsks .checkbox label p {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 12px;
  padding: 2px 0;
}
.english .chsks .checkbox label p {
  float: left;
  width: 100%;
  color: #000000;
  font-size: 12px;
  padding: 2px 0;
}
.arabic .chsks .checkbox label {
  box-shadow: 0 0 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  overflow: hidden;
  border: 0;
  margin-bottom: 15px;
  padding: 10px;
  padding-right: 45px;
}
.english .chsks .checkbox label {
  box-shadow: 0 0 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  overflow: hidden;
  border: 0;
  margin-bottom: 15px;
  padding: 10px;
  padding-left: 45px;
}
.hed_label > i {
  color: #056938;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  display: inline-block;
}
.hed_label small {
  display: inline-block;
  color: #585858;
  font-size: 10px;
  font-weight: normal;
}
.hed_label ul li:last-child {
  margin-left: 0;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.arabic .hed_label ul li {
  float: right;
  margin-left: 15px;
  width: 22px;
  height: 22px;
  color: #000000;
  background: #f0f0f0;
  text-align: center;
  line-height: 22px;
  font-size: 10px;
  border-radius: 3px;
}
.english .hed_label ul li {
  float: right;
  margin-right: 15px;
  width: 22px;
  height: 22px;
  color: #000000;
  background: #f0f0f0;
  text-align: center;
  line-height: 22px;
  font-size: 10px;
  border-radius: 3px;
}
.arabic .hed_label ul {
  float: left;
  position: absolute;
  left: 0px;
  top: 7px;
}
.english .hed_label ul {
  float: left;
  position: absolute;
  right: 0px;
  top: 7px;
}
.hed_label {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 12px !important;
  font-weight: bold;
}
.btn_add button i {
  display: inline-block;
  margin-left: 8px;
}
.btn_add button {
  float: right;
  width: 100%;
  color: #fff;
  background: #056938;
  border: 0;
  font-size: 14px;
  padding: 8px 10px 12px;
  border-radius: 7px;
}
.btn_add {
  float: right;
  width: 100%;
}
.s_2 {
  padding: 10px;
}
.input_label label.req {
  position: relative;
}
.input_label .req:before {
  content: "*";
  color: #e82629;
  font-size: 12px;
  display: inline-block;
  left: 0;
  padding: 0 4px;
}
.chsks .input_15 input {
  padding-right: 10px;
}
.input_15 input {
  padding-right: 40px;
}
.tables {
  float: right;
  width: 100%;
  overflow-x: auto;
}
.table_s th {
  background: #056938;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 15px 10px;
}
.table_s tr:nth-child(odd) td {
  background: #fafafa;
}
.table_s td a {
  float: right;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  color: #000000;
  font-size: 10px;
  padding: 5px 10px 8px;
  background: #fff;
  text-align: center;
}
.table_s td {
  background: #fff;
  color: #000000;
  font-size: 12px;
  padding: 5px 10px 8px;
  vertical-align: middle;
}
.table_s {
  float: right;
  width: 100%;
}
.arabic .detil_6 p span {
  float: left;
}
.arabic .col-md-12-custom {
  float: none;
}
.english .col-md-12-custom {
  float: none;
}
.arabic .col-md-6-custom {
  float: right;
}
.english .col-md-6-custom {
  float: left;
}
.english .detil_6 p span {
  float: right;
}
.arabic .pays label {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px 40px 12px 15px;
  min-width: 140px;
  max-width: 100%;
}
.english .pays label {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px 15px 12px 40px;
  min-width: 140px;
  max-width: 100%;
}
.detil_6 p {
  float: right;
  width: 100%;
  background: #fafafa;
  padding: 10px 35px 12px;
  font-size: 14px;
  color: #000000;
}
.detil_6 {
  float: right;
  width: 100%;
  /* background: #fff; */
  /* border: 1px solid #d1d1d1; */
  /* box-shadow: 0 0 30px rgb(0 0 0 / 11%);  */
  margin-bottom: 15px;
}
p.total_6 {
  border-top: 1px solid #056938;
  margin-bottom: 30px;
}
.p_6 {
  margin: 5px 0;
}
.marb_10 {
  margin-bottom: 35px;
}
.no_input label:before {
  display: none;
}
.no_input label {
  box-shadow: none !important;
  padding: 0 !important;
}
.img_td img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.img_td {
  float: right;
  width: 45px;
  height: 45px;
  overflow: hidden;
  position: relative;
}
.img_td-en {
  float: left;
  width: 65px;
  height: 65px;
  overflow: hidden;
  position: relative;
}
.arabic.inputs_alls {
  float: right;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  box-shadow: none !important;
}
.arabic.inputs_alls .img_td {
  width: 85px;
  height: 85px;
}
.delete {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #efefef;
  color: #e84141;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.cont_ints input {
  float: right;
  width: 40px;
  border: 0;
  border-radius: 0;
  text-align: center;
  font-size: 16px;
  color: #000000;
  background: transparent;
}
.cont_ints button:nth-child(2) {
  right: auto;
  left: 0;
}
.cont_ints button {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: #f5f5f5;
  color: #000;
  /* line-height: 24px; */
  text-align: center;
  border-radius: 100%;
  border: 0;
}
/* .cont_ints.mob button {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  top: 0;
  padding: 5px;
  background: #f5f5f5;
  color: #000;
  line-height: 24px;
  text-align: center;
  border-radius: 100%;
  border: 0;
} */
.cont_ints.mob button i {
  height: 20px;
  width: 20px;
  overflow: hidden;
}

.cont_ints {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
}
.tex_td > div {
  display: table-cell;
  vertical-align: middle;
}
.img_tds {
  width: 85px;
}
.texs_td {
  padding-right: 15px;
  text-align: right;
}
.tex_td {
  float: right;
  width: 100%;
  display: table;
  min-width: 250px;
  font-size: 14px;
}
.arabic.inputs_alls .table_s tr:nth-child(odd) td {
  background: transparent;
}
.arabic.inputs_alls .table_s tr {
  border-bottom: 1px solid #ededed;
}
.arabic.inputs_alls .table_s td {
  text-align: center;
}
.arabic.inputs_alls .table_s th {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 25px 10px;
  text-align: center;
}
.arabic .btns_ls button {
  float: left;
  background: #056938;
  color: #fff;
  font-size: 14px;
  padding: 7px 15px 10px;
  border-radius: 25px;
  margin-right: 15px;
  border: 0;
  width: 150px;
  max-width: 100%;
}
.english .btns_ls button {
  float: right;
  background: #056938;
  color: #fff;
  font-size: 14px;
  padding: 7px 15px 10px;
  border-radius: 25px;
  margin-right: 15px;
  border: 0;
  width: 150px;
  max-width: 100%;
}
.btns_ls label span {
  font-weight: bold;
  float: left;
  padding-right: 15px;
}
.btns_ls label {
  float: left;
  background: #efefef;
  color: #000000;
  font-size: 14px;
  padding: 7px 15px 10px;
  border-radius: 25px;
}
.btns_ls {
  float: left;
  width: 100%;
  padding: 40px;
}
.login_form h3 {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.login_form p {
  float: right;
  width: 100%;
  color: #535353;
  font-size: 15px;
  margin-bottom: 15px;
}
.login_form .input_label {
  margin-bottom: 0;
}
.login_form .input_15 {
  margin-bottom: 10px;
}
.login_form a {
  float: left;
  color: #000000;
  font-size: 10px;
  font-weight: bold;
  padding-top: 8px;
}
.login_form .input_15 button {
  float: right;
  width: 100%;
  border: 0;
  font-size: 14px;
  padding: 8px 15px 10px;
  color: #fff;
  background: #056938;
  margin-top: 15px;
}
.login_form span a {
  float: none;
  display: inline-block;
  margin-right: 10px;
  padding-top: 0;
}
.login_form span {
  float: right;
  width: 100%;
  text-align: center;
  color: #707070;
  font-size: 13px;
}
.login_form {
  float: right;
  width: 100%;
  background: #ffffff;
  padding: 40px 20px;
}
.arabic .checkbox.remmeber {
  float: right;
  width: auto;
  border-bottom: 0;
  margin: 0;
}
.text_login h3 {
  float: right;
  width: 100%;
  font-size: 20px;
  color: #056938;
  font-weight: bold;
  margin-bottom: 10px;
}
.text_login a {
  float: right;
  color: #fff;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #ffffff;
  font-size: 14px;
  padding: 6px 15px 12px;
  width: 170px;
  max-width: 100%;
  text-align: center;
  margin-top: 15px;
}
.text_login ul li:before {
  content: "";
  position: absolute;
  right: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background: #056938;
  border-radius: 3px;
  transform: rotate(45deg);
}
.text_login ul li {
  font-size: 12px;
  padding-right: 15px;
  position: relative;
}
.pt_5 {
  padding-top: 60px !important;
}
.text_login ul {
  float: right;
  width: 100%;
  padding-top: 45px;
}
.text_login ul li,
.text_login p {
  float: right;
  width: 100%;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 5px;
}
.text_login {
  float: right;
  width: 100%;
  padding: 25px 0;
}
.bg_login:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  background: url("assets/images/little-grey-kitten-with-blue-eyes-lies-gresy-couch.png")
    no-repeat center center;
  background-size: cover;
  z-index: -1;
}
.bg_login .head_sec-ar h3,
.bg_login .head_sec-en h3 {
  color: #fff;
}
.bg_login {
  position: relative;
  overflow: hidden;
  padding: 50px 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 170, 0) 100%
  );
}
section.m_add_to_card {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transform: translateY(100%);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.m_card .close_card {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
  padding: 5px;
  cursor: pointer;
}
.m_add_to_card.active {
  max-height: 9999999px;
  transform: translateY(0px);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.m_add_to_card {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.m_card {
  float: right;
  width: 100%;
  background: #056938;
  padding: 15px 45px;
  position: relative;
}
.notfi_en .m_card {
  display: flex;
}
.notfi_en .m_card .close_card {
  left: auto;
  right: 15px;
}
.notfi_en .m_card .card_add {
  display: flex;
  width: 100%;
}
.notfi_en .m_card .card_add .img_card_adds .img_card_add {
  margin-left: 0;
  margin-right: 15px;
}
.notfi_en .m_card .card_add .text_card_adds {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}
.notfi_en .m_card .card_add .text_card_adds p {
  margin: 0;
}
.notfi_en .m_card .card_add .text_card_adds span i {
  margin-right: 5px;
}
.notfi_en .m_card .url_card_adds {
  width: 350px;
}

.notfi_ar .m_card {
  display: flex;
}
.notfi_ar .m_card .close_card {
  left: 15px;
  right: auto;
}
.notfi_ar .m_card .card_add {
  display: flex;
  width: 100%;
}
.notfi_ar .m_card .card_add .img_card_adds .img_card_add {
  margin-left: 15px;
  margin-right: 0;
}
.notfi_ar .m_card .card_add .text_card_adds {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}
.notfi_ar .m_card .card_add .text_card_adds p {
  margin: 0;
}
.notfi_ar .m_card .card_add .text_card_adds span i {
  margin-right: 0;
  margin-left: 5px;
}
.notfi_ar .m_card .url_card_adds {
  width: 350px;
}

.card_add > div {
  display: table-cell;
  vertical-align: middle;
}
.card_add {
  float: right;
  display: table;
}
.img_card_add img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 90%;
}
.img_card_add {
  float: right;
  width: 115px;
  height: 85px;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  margin-left: 15px;
}
.text_card_adds p {
  float: right;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 20px;
}
.text_card_adds span {
  float: right;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
}
.url_card_adds ul li:last-child {
  margin-left: 0;
}
.url_card_adds ul li a:hover {
  background: #056938;
  color: #fff;
  border: 1px solid #fff;
}
.url_card_adds ul li a {
  float: right;
  width: 100%;
  background: #fff;
  color: #056938;
  font-size: 12px;
  border-radius: 26px;
  overflow: hidden;
  padding: 7px 15px 10px;
  border: 1px solid #056938;
  width: 120px;
  max-width: 100%;
  text-align: center;
}
.url_card_adds ul li {
  float: right;
  margin-left: 15px;
}
.url_card_adds ul {
  float: right;
}
.url_card_adds {
  float: left;
  padding-top: 35px;
}
.alert i {
  margin-left: 15px;
}
.alert {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  margin-top: 15px;
}
.detil_6 h3 {
  float: right;
  width: 100%;
  background: #f0f0f0;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 15px 10px;
  text-align: center;
}
.lists_produv {
  float: right;
  width: 100%;
}
.lists_produvs > div {
  display: table-cell;
  vertical-align: top;
}
.lists_produvs:last-child {
  border-bottom: 0;
  margin-bottom: 65px;
}
/* .lists_produvs {
  float: right;
  width: 100%;
  display: table;
  position: relative;
  padding: 15px 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #056938;
  border-bottom-width: 75%;
} */
.lists_produvs {
  /* float: right; */
  width: 100%;
  display: table;
  position: relative;
  padding: 15px 25px;
  margin-bottom: 15px;
}

.lists_produvs::after {
  content: "";
  display: block;
  width: 95%;
  border-bottom: 1px solid #056938;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 25px;
}

.img_lists_prod {
  width: 60px;
}
.img_lists_prod img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  max-width: 100%;
}
.img_lists_prod {
  /* float: right; */
  width: 80px;
  height: 120px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.text_list_prodv p {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 14px;
  background: transparent;
  padding: 0;
  margin-bottom: 5px;
}
.text_list_prodv span {
  float: right;
  color: #056938;
  font-size: 12px;
  font-weight: bold;
}
.text_list_prodv .cont_ints {
  float: left;
}
.lists_produvs > i {
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 5px;
  z-index: 1;
  width: 30px;
  height: 30px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.radio label:before {
  border-radius: 15px;
}
.done_comple {
  float: right;
  width: 100%;
  padding: 30px 15px;
  text-align: center;
}
.done_comple h3 {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}
.done_comple p {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 16px;
  margin-bottom: 10px;
}
.done_comple img {
  display: block;
  max-width: 100%;
  margin: 15px auto;
}
.done_comple a {
  display: inline-block;
  width: 230px;
  max-width: 100%;
  border-radius: 25px;
  color: #fff;
  padding: 8px 15px 10px;
  background: #056938;
  border: 0;
  text-align: center;
}
.done_comple span {
  float: right;
  width: 100%;
  color: #8d8d8d;
  font-size: 16px;
  margin-bottom: 10px;
}
.dis span i {
  display: inline-block;
  /* margin-left: 15px; */
  color: #056938;
  width: 25px;
  text-align: center;
}
.dis > span {
  float: right;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000000;
  display: inline-flex;
  align-items: center;
  gap: 15px;
}
.dis {
  float: right;
  width: 100%;
  padding: 15px;
}
.dis span p {
  width: 100%;
  background: transparent;
  padding: 0;
}
.dis.nopad {
  padding: 0;
}
.text_list_prodv .count_97 {
  float: left;
  color: #000;
  font-size: 14px;
}
/* .done_comple ul li:last-child {
  margin-left: 0;
} */
.done_comple ul li:last-child a {
  background: transparent;
  color: #056938;
  border: 1px solid #056938;
}
.done_comple ul li a {
  width: 190px;
  max-width: 100%;
  border: 1px solid #056938;
}
.done_comple ul li {
  display: inline-block;
  /* margin-left: 15px; */
}
.done_comple.not {
  background: #f4f4f4;
  border-radius: 24px;
  margin-top: 30px;
}
.done_comple ul {
  float: right;
  width: 100%;
}
.slid_filter {
  border-radius: 20px;
  overflow: hidden;
}
.footer_main .soial li a,
.img_item_filter img,
.slid_filter img,
.img_item_lists_con_page a img,
.item_list_slid_page img,
.types a,
.list_marks_icons .item a img,
.app_fs ul li,
.img_images a img,
.icon_soial li img,
.footer_main p i,
.img_products img {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.types a:hover,
.app_fs ul li:hover,
.footer_main p:hover i,
.icon_soial li img:hover,
.footer_main .soial li a:hover {
  transform: scale(1.2);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.img_item_filter:hover img,
.slid_filter:hover img,
.img_item_lists_con_page a:hover img,
.item_list_slid_page:hover img,
.list_marks_icons .item a:hover img,
.img_images a:hover img,
::ng-deep .item_products ::ng-deep > a:hover img {
  transform: translate(-50%, -50%) scale(1.1);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
#loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 30%);
  z-index: 2;
}
.loader {
  position: absolute;
  bottom: 50%;
  top: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.js-image-zoom__zoomed-image {
  transform: translateX(-100%) !important;
  z-index: 1;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.carousel-style-en .owl-stage.ng-tns-c72-0 {
  /* max-width: fit-content; */
  float: left;
  direction: ltr;
}
.carousel-style-ar .owl-stage.ng-tns-c72-0 {
  /* max-width: fit-content; */
}
.carousel-style-ar
  .owl-item.ng-tns-c72-0.ng-trigger.ng-trigger-autoHeight.active.ng-star-inserted {
  max-width: 120px;
}
.carousel-style-en
  .owl-item.ng-tns-c72-0.ng-trigger.ng-trigger-autoHeight.active.ng-star-inserted {
  max-width: 120px;
}
.category_image_carousel {
  height: 100%;
  object-fit: contain;
}
/* input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="string"],
textarea {
  font-size: 16px;
} */

.sub_menu_cat {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1170px;
  z-index: 9;
  top: 100%;
  /* height: 84vh; */
  max-height: 0;
  /* background: rgb(0 0 0 / 35%); */
  /* box-shadow: 0 0 12px rgb(0 0 0 / 15%); */
  overflow: auto;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  overflow: hidden;
}
.sub_menu_cat:hover .sub_menu_cats,
.bg_sub_menu_cat:hover + .sub_menu_cat,
.index_menu-ar > ul > li > a:hover + .sub_menu_cat .sub_menu_cats,
.index_menu-en > ul > li > a:hover + .sub_menu_cat .sub_menu_cats {
  overflow: hidden;

  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.sub_menu_cats {
  background: white;
}
.index_menu-en {
  z-index: 11;
}
.sub_menu_cats:hover + .val,
.index_menu-ar > ul > li > a:hover + .sub_menu_cat .val,
.index_menu-en > ul > li > a:hover + .sub_menu_cat .val {
  width: 100vw;
  height: 100vh;
  /* height: calc(100vh-110px); */
  position: fixed;
  top: 150px;
  left: 0px;
  content: "";
  z-index: -1;
  background: #3a3a3a72;
  backdrop-filter: blur(5px);
  /* background: blur(5px); */
}
.sub_menu_cat:hover,
.index_menu-ar > ul > li > a:hover + .sub_menu_cat,
.index_menu-en > ul > li > a:hover + .sub_menu_cat {
  max-height: 9999999px;
  box-shadow: 0px 15px 10px rgb(0 0 0 / 15%);
  margin-top: -2px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
/* .bg_sub_menu_cat {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 35%);
  z-index: 9;
} */
.arabic .prodDet .owl-nav .owl-prev,
.english .slid_page .owl-nav .owl-prev {
  left: -20px !important;
}
.arabic .prodDet .owl-nav .owl-next,
.english .slid_page .owl-nav .owl-next {
  right: -60px !important;
}
.sub_menu_cats > div {
  display: table-cell;
  vertical-align: top;
}
.sub_menu_cats {
  float: right;
  width: 100%;
  background: #ffffff;
  position: relative;
  z-index: 9;
  display: table;
  transform: translateY(-10px);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
}
.sub_menu-ar,
.sub_menu-en {
  border: 1px solid #e2e2e2;
  background: #f2f2f2;
  width: 190px;
  max-width: 30%;
}
.sub_menu-en ul li a:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 20px;
  color: #000000;
  font-size: 10px;
}
.sub_menu-ar ul li a:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 20px;
  color: #000000;
  font-size: 10px;
}
.sub_menu-ar ul li a:hover,
.sub_menu-en ul li a:hover,
.sub_menu-ar ul li a.active,
.sub_menu-en ul li a.active {
  color: #056938;
}
.sub_menu-ar ul li a,
.sub_menu-en ul li a {
  float: right;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: #000000;
  position: relative;
  cursor: pointer;
}
.sub_menu-ar ul li:last-child,
.sub_menu-en ul li:last-child {
  border-bottom: 0;
}
.sub_menu-ar ul li,
.sub_menu-en ul li {
  float: right;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.sub_menu-ar ul li a img,
.sub_menu-en ul li a img {
  float: right;
  max-width: 22px;
  max-height: 22px;
  margin-left: 10px;
  margin-top: 4px;
}
.items_sub_menu {
  padding: 30px 60px 30px 4px;
}
.li_smn,
.item_sub_menu {
  float: right;
  width: 100%;
}
.item_sub_menu.active {
  display: inline-block;
}
.item_sub_menu {
  display: none;
}
.li_smn {
  margin-bottom: 30px;
  min-height: 200px;
}
.li_smn ul li a:hover {
  padding-right: 15px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.li_smn ul li a {
  float: right;
  width: 100%;
  padding: 5px 0;
  color: #000000;
  font-size: 12px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.li_smn ul li {
  float: right;
  width: 100%;
}
.li_smn ul {
  float: right;
  width: 100%;
}
.li_smn h4 {
  float: right;
  width: 100%;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.li_smn_img a:hover img {
  transform: translate(-50%, -50%) scale(1.1);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.li_smn_img a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  object-fit: contain;
}
.li_mark-ar ul li a img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.li_mark-en ul li a img {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.li_smn_img a {
  float: right;
  width: 100%;
  position: relative;
  height: 320px;
  overflow: hidden;
  border-radius: 25px;
}
.li_smn_img {
  float: right;
  width: 100%;
}
a.li_smn_more {
  float: right;
  width: 100%;
  color: #4e71b1;
  font-size: 14px;
  margin-top: 10px;
}
.li_mark-ar h3 {
  float: right;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.li_mark-en h3 {
  float: left;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.li_mark-ar {
  float: right;
  width: 100%;
  padding: 40px 0 20px;
}
.li_mark-en {
  float: left;
  width: 100%;
  padding: 40px 0 20px;
}
.li_mark-ar ul li a {
  float: right;
  width: 115px;
  height: 85px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 0 12px rgb(0 0 0 / 5%);
  position: relative;
  overflow: hidden;
}
.li_mark-en ul li a {
  float: left;
  width: 115px;
  height: 85px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 0 12px rgb(0 0 0 / 5%);
  position: relative;
  overflow: hidden;
}
.li_mark-ar ul li {
  float: right;
  margin-left: 25px;
}
.li_mark-en ul li {
  float: left;
  margin-left: 25px;
}
.li_mark-ar ul {
  float: right;
  width: 100%;
}
.li_mark-en ul {
  float: left;
  width: 100%;
}
.li_mark-ar ul li:last-child a {
  width: auto;
  box-shadow: none;
  height: auto;
  color: #4e71b1;
  font-size: 16px;
  overflow: initial;
  margin-top: 25px;
}
.li_mark-en ul li:last-child a {
  width: auto;
  box-shadow: none;
  height: auto;
  color: #4e71b1;
  font-size: 16px;
  overflow: initial;
  margin-top: 25px;
}
.text_list_prodv .cont_ints button i {
  font-weight: bold;
}
.text_list_prodv .cont_ints button {
  font-size: 8px;
}
.loked span i {
  float: left;
  margin-right: 10px;
  margin-top: 6px;
}
.loked span {
  float: left;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.loked > div {
  float: right;
  width: 100%;
  padding: 30px 0;
  background: #056938;
}
.con_con-ar .btn_item_products button:first-child,
.con_con-en .btn_item_products button:first-child {
  width: 190px;
}
.loked a {
  float: right;
  font-size: 18px;
  color: #fff;
  padding: 0px 10px;
}
.catds {
  float: right;
  width: 100%;
}
.catd > div {
  display: table-cell;
  vertical-align: middle;
}
.catd {
  float: right;
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid #ededed;
  display: table;
}
.catds_imgs img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 60%;
  min-width: 60%;
  max-width: 100%;
}
.catds_imgs {
  float: right;
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.catds_img {
  width: 150px;
}
.catds_txt .cont_ints {
  width: 100px;
}
.catds_txt {
  padding: 0 10px;
}
.no_mo_tact2 {
  display: none;
}
.catds_txt span {
  float: right;
  width: 100%;
  margin-bottom: 10px;
}
.catds_txt h3 {
  float: right;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
}
.catds_txt .delete {
  border: 0;
  width: auto;
  float: left;
  font-size: 14px;
  margin-top: -5px;
}
.catds_txt .delete_en {
  float: right;
}
.arabic .list_cards ul li span {
  float: left;
}
.english .list_cards ul li span {
  float: right;
}
.list_cards ul li {
  float: right;
  width: 100%;
  font-size: 16px;
  padding: 5px 0;
  font-weight: bold;
}
.list_cards ul {
  float: right;
  width: 100%;
}
.list_cards {
  padding: 10px;
  float: right;
  width: 100%;
}
.mo_filter {
  float: right;
  width: 100%;
  display: none;
}
.mo_filter > span.chevron {
  margin-left: 0;
}
.arabic .mo_filter > span i {
  display: inline-block;
  margin-left: 10px;
}
.english .mo_filter > span i {
  display: inline-block;
  margin-right: 10px;
}
.mo_filter > span {
  display: inline-block;
  width: 48%;
  padding: 8px 5px 10px;
  border: 1px solid #a7a7a7;
  color: #a7a7a7;
  text-align: center;
  margin-left: 1.5%;
  font-size: 16px;
}
.mo_filter {
  float: right;
  width: 100%;
  text-align: center;
  padding: 0px 15px 20px;
}
.all_sub_menu2.active,
.all_sub_menu.active {
  display: inline-block;
}
.all_sub_menu2,
.all_sub_menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}
.bg_all_sub_menu2,
.bg_all_sub_menu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 30%);
}
.all_sub_menu .sub_menu-ar > ul > li > ul > li a:before,
.all_sub_menu .sub_menu-en > ul > li > ul > li a:before {
  display: none;
}
.all_sub_menu .sub_menu-ar > ul > li > ul li,
.all_sub_menu .sub_menu-en > ul > li > ul li {
  float: right;
  width: 100%;
}
.all_sub_menu .sub_menu-ar > ul > li a.active + ul,
.all_sub_menu .sub_menu-en > ul > li a.active + ul {
  display: inline-block;
}
.all_sub_menu .sub_menu-ar > ul > li > ul,
.all_sub_menu .sub_menu-en > ul > li > ul {
  float: right;
  width: 100%;
  display: none;
}
.all_sub_menu2 .sub_menu2 {
}
.english .all_sub_menu2 .sub_menu2,
.english .all_sub_menu .sub_menu-ar,
.english .all_sub_menu .sub_menu-en {
  position: absolute;
  width: 80%;
  background: #dfdfdf;
  z-index: 1;
  left: 0;
  max-width: 80%;
  height: 100%;
  padding-top: 30px;
  text-align: left;
  overflow-y: auto;
}
.arabic .all_sub_menu2 .sub_menu2,
.arabic .all_sub_menu .sub_menu-ar,
.arabic .all_sub_menu .sub_menu-en {
  position: absolute;
  width: 80%;
  background: #dfdfdf;
  z-index: 1;
  right: 0;
  max-width: 80%;
  height: 100%;
  padding-top: 30px;
  text-align: right;
  overflow-y: auto;
}
.sub_menu2 .sle .bootstrap-select .filter-option-inner {
  text-align: right;
}
.sub_menu2 .sle .bootstrap-select {
  width: 100% !important;
}
.sub_menu2 .sle {
  float: right;
  width: 100%;
  padding: 5px 15px;
}
.sub_menu2 .arabic .checkbox {
  padding: 0 10px;
}
.sub_menu2 .lists_folter-ar h3,
.sub_menu2 .lists_folter-en h3 {
  padding: 8px 15px;
  margin-top: 10px;
}
.sub_menu2 h6 {
  float: right;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.swiper-button-next:after {
  font-size: 10px;
}
.swiper-button-prev:after {
  font-size: 10px;
}
.btn_counts input {
  float: right;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #000;
  /* color: #d8d8d8; */
  /* padding: 5px 20px 6px; */
  border: 1px solid #d8d8d8;
  height: 30px;
}
.btn_counts button.minus {
  left: 0;
  right: auto;
}
.btn_counts button {
  position: absolute;
  right: 0;
  background: #d8d8d8;
  color: #777;
  border-radius: 0px;
  border: 1px solid #d8d8d8;
  font-size: 10px;
  width: 30px;
  height: 30px;
}
.btn_counts {
  float: right;
  /* width: Calc(100% - 50px); */
  width: 100%;
  position: relative;
  overflow: hidden;
}
.con_con-ar .btn_counts,
.con_con-en .btn_counts {
  max-width: 180px;
}
.list_alpha3 ul {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
}
.div-center {
  position: relative;
}
.div-center .owl-nav {
  position: relative;
}
.div-center .owl-carousel {
  margin-bottom: 20px;
}
.list-brand-en {
  justify-content: flex-start;
}
.list-brand-ar {
  justify-content: flex-start;
}
.brand-container {
  width: 165px;
  height: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.brand-container img {
  width: 100%;
  margin-bottom: 5px;
}
.no_web {
  display: none !important;
}
.noArrow::before {
  display: none;
}
.viewMob {
  display: block;
  color: #000000;
}
.hideMob {
  display: none;
}
.list_slid_page .owl-carousel .owl-stage-outer .owl-stage .owl-item {
  width: auto !important;
}

.baseColor{
  color: #056938 !important;
}

.text-16{
  font-size: 16px !important;
}













@media (min-width: 1400px) {
  .container {
    width: 1340px;
  }
}
@media (min-width: 1500px) {
  .container {
    width: 1460px;
  }
}




@media (max-width: 1200px) {
  .img_contacts {
    display: none;
  }
  .li_smn {
    padding: 10px 0;
  }
  .items_sub_menu {
    padding: 15px;
  }
  .li_mark-ar ul li {
    margin-left: 10px;
  }
  .li_mark-en ul li {
    margin-right: 10px;
  }
}
@media (max-width: 992px) {
  .slid_filter {
    height: 150px;
  }
  .list_help {
    margin-bottom: 15px;
  }
}

@media (max-width: 772px) {

  .input_15 select,
  .input_15 input {
    font-size: 16px;
  }

  .cont_ints button {
    height: 30px !important;
  }

  .notfi_ar .m_card,
  .notfi_en .m_card {
    flex-direction: column;
  }

  .notfi_en .m_card .url_card_adds ul {
    float: left;
  }

  .notfi_en .m_card .close_card {
    left: auto;
    right: 0px;
    top: 0;
  }

  .notfi_ar .m_card .close_card {
    left: 0;
    right: auto;
    top: 0;
  }

  .owl-prev {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }

  .arabic .owl-prev {
    left: -10px;
  }

  .arabic .owl-next {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    right: -30px;
  }

  .col-md-3-custom {
    width: 100%;
  }

  .arabic .col-md-1,
  .arabic .col-md-2,
  .arabic .col-md-3,
  .arabic .col-md-4,
  .arabic .col-md-5,
  .arabic .col-md-6,
  .arabic .col-md-7,
  .arabic .col-md-8,
  .arabic .col-md-9,
  .arabic .col-md-10,
  .arabic .col-md-11,
  .arabic .col-md-12 {
    float: right;
    padding-left: 15px;
  }

  .english .col-md-1,
  .english .col-md-2,
  .english .col-md-3,
  .english .col-md-4,
  .english .col-md-5,
  .english .col-md-6,
  .english .col-md-7,
  .english .col-md-8,
  .english .col-md-9,
  .english .col-md-10,
  .english .col-md-11,
  .english .col-md-12 {
    float: left;
  }

  .image_slid_page {
    overflow: hidden;
  }

  .mob_slider_width {
    width: 100%;
    padding: 0;
  }

  .arabic .slid_page ::ng-deep .owl-next,
  .arabic .slid_page ::ng-deep .owl-prev {
    right: -15px;
  }

  .english .slid_page ::ng-deep .owl-next,
  .english .slid_page ::ng-deep .owl-prev {
    left: -15px;
  }

  .con_filter .col-xs-6 .btn_item_products button {
    font-size: 9.5px;
  }

  .con_filter .col-xs-6 .item_products ul li {
    font-size: 14px;
  }

  .con_filter .col-xs-6 .item_products a p {
    font-size: 14px;
  }

  .con_filter .col-xs-6 .item_products {
    padding: 7px;
  }

  .con_filter .col-xs-6 {
    padding: 0 0px;
  }

  .con_con-ar .btn_item_products button {
    margin-left: 7px;
  }

  .con_con-en .btn_item_products button {
    margin-right: 7px;
  }

  .con_con-ar .btn_counts,
  .con_con-en .btn_counts {
    width: 165px;
  }

  .con_con-ar .btn_item_products button:first-child,
  .con_con-en .btn_item_products button:first-child {
    width: 165px;
  }

  .empty {
    padding: 30px 15px;
  }

  .mo_filter {
    padding: 0 0 15px;
  }

  .no_mob {
    display: none !important;
  }

  .no_web {
    display: block !important;
  }

  .no_mo_tact {
    display: none;
  }

  .no_mo_tact2 {
    display: inline-block;
  }

  .in_amobile {
    display: inline-block;
  }

  .btns_ls button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .done_comple ul li {
    width: 100%;
    /* margin-bottom: 15px; */
  }

  .page_4:after {
    display: none;
  }

  .e404:before,
  .e404:after {
    opacity: 0.4;
  }

  .page_4 h3 {
    text-shadow: 1px 1px 10px rgb(0 0 0 / 40%);
  }

  .inputs_all {
    padding: 15px 10px;
  }

  .menu_15 {
    width: 100%;
  }

  .text_p_imgs:before {
    display: none;
  }

  .list_helps {
    display: none;
  }

  .owl-prev {
    left: 5px !important;
  }

  .english .owl-next {
    right: -30px;
  }

  /*.owl-prev,.owl-nuxt{
      display: none !important;
  }*/
  .list_marks_icons2,
  .list_marks_icons {
    padding: 10px 0 0;
  }

  .m_card {
    padding: 15px;
  }

  .ts_7s:before {
    display: none;
  }

  .js-image-zoom__zoomed-image {
    display: none !important;
  }

  .con_con-ar .btn_item_products .dropdown-share button:last-child {
    margin-left: 0;
  }

  .con_con-en .btn_item_products button:last-child {
    margin-right: 0;
  }

  .menu_15 {
    min-height: 350px;
  }

  .input_15 {
    overflow: hidden;
  }

  .m_add_to_card {
    background: #056938;
  }

  .mo_filter {
    display: inline-block;
  }

  .left_header_filters2-ar ul li {
    margin-top: 8px;
  }

  .m-200{
    margin-top: 0px !important;
  }
}



@media (max-width: 760px){
  .head_sec-en h3:before {
    content: none !important;
  }

  .head_sec-ar h3:before {
    content: none !important;
  }
}
